import { Fragment, useState, useMemo } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import './App.css';
import requestSchema from './requestNew.json';
import requestUISchema from './request_uischema.json'
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import RatingControl from './RatingControl';
import ratingControlTester from './ratingControlTester';
import { makeStyles } from '@mui/styles';
import {v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  container: {
    padding: '1em',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
    marginBottom: '1rem',
  },
  resetButton: {
    margin: 'auto !important',
    display: 'block !important',
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
  },
});

const tiRequestID = uuidv4();
const date = new Date();
const tiRequestTimestamp = date.toISOString();

const initialData = {
  tiRequestSet: {
    tiRequestAuditReferences: {
      tiRequestID: tiRequestID,
      tiRequestTimestamp: tiRequestTimestamp,
    }
  }
};

const renderers = [
  ...materialRenderers,
  //register custom renderers
  { tester: ratingControlTester, renderer: RatingControl },
];

const Request = () => {
  const classes = useStyles();
  const [data, setData] = useState<any>(initialData);

  // Function to handle form data download
  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(data)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = "request.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <Fragment>
    

      <Grid
        container
        justifyContent={'center'}
        spacing={1}
        className={classes.container}
      >
        <Grid item sm={6}>
          <Typography variant={'h4'} className={classes.title}>
            TI Request Form
          </Typography>
          <div className={classes.demoform}>
            <JsonForms
              schema={requestSchema as any}
              uischema={requestUISchema}
              data={data}
              renderers={renderers}
              cells={materialCells}
              onChange={({ errors, data }) => setData(data)}
            />
          </div>
          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
                >
                Download TI Request File
            </Button>
            </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Request;