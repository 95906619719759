import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink } from 'react-router-dom';
import Request from './Request';
import Response from './Response';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});


export const App = () => {
  const classes = useStyles();
  
  return (
    <Router>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Box>
            <Button component={RouterLink} to="/request" color="inherit">
              Request
            </Button>
            <Button component={RouterLink} to="/response" color="inherit">
              Response
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/request" element={<Request />} />
        <Route path="/response" element={<Response />} />
      </Routes>
    </Router>
  );
};

export default App;