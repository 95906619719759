import { Fragment, useState } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import { Box } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import Typography from '@mui/material/Typography';
import './App.css';
import responseSchema from './responseNewDeref.json';
import responseUISchema from './response_uischema.json'
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import RatingControl from './RatingControl';
import JsonDisplay from './JsonDisplay';
import ratingControlTester from './ratingControlTester';
import { makeStyles } from '@mui/styles';
import {v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
  container: {
    padding: '1em',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
    marginBottom: '1rem',
  },
  resetButton: {
    margin: 'auto !important',
    display: 'block !important',
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
  },
  input: {
    display: 'none',
  }
});

const tiResponseID = uuidv4();
const date = new Date();
const tiResponseTimestamp = date.toISOString();

const initialData = {
    tiResponse: {
      tiResponseAuditReferences: {
        tiResponseID: tiResponseID,
        tiResponseTimestamp: tiResponseTimestamp,
      }
    }
  };


const renderers = [
  ...materialRenderers,
  //register custom renderers
  { tester: ratingControlTester, renderer: RatingControl },
];



const Response = () => {
    const classes = useStyles();
    const [data, setData] = useState<any>(initialData);
    const [uploadedData, setUploadedData] = useState<any>(null);
    

    // Function to handle form data download
    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(data)], {type: 'application/json'});
        element.href = URL.createObjectURL(file);
        element.download = "response.json";
        document.body.appendChild(element);
        element.click();
    };

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const json = JSON.parse(e.target?.result as string);
              setUploadedData(json);
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          };
          reader.readAsText(file);
        }
      };

      const dictionary = {
        // Define your key-to-display name mapping here
        "key1": "Display Name 1",
        "key2": "Display Name 2",
        // ...
      };
    
      return (
        <Fragment>
          <Grid
            container
            justifyContent={'center'}
            spacing={1}
            className={classes.container}
          >
            <Grid item sm={6}>
              <Typography variant={'h4'} className={classes.title}>
                TI Response Form
              </Typography>
              <div className={classes.demoform}>
                <JsonForms
                  schema={responseSchema as any}
                  uischema={responseUISchema}
                  data={data}
                  renderers={renderers}
                  cells={materialCells}
                  onChange={({ errors, data }) => setData(data)}
                />
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                  >
                    Download TI Response File
                  </Button>
                </Box>
              </div>
            </Grid>
            <Grid item sm={6}>
              <Typography variant={'h4'} className={classes.title}>
                Uploaded Request Data
              </Typography>
              {uploadedData && <JsonDisplay data={uploadedData} dictionary={dictionary} />}
              <Box display="flex" justifyContent="center" m={1} p={1}>
                <input
                  accept="application/json"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUpload}
                  style={{ display: 'none' }}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <BackupIcon /> Upload Request JSON
                  </Button>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      );

      
      
      
    };
  
export default Response;