import React from 'react';
import { JsonTable } from 'react-json-to-html';
import { makeStyles } from '@mui/styles';

type JsonDisplayProps = {
  data: any;
  dictionary: { [key: string]: string };
};

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    fontSize: '12px'
  },
});

const JsonDisplay: React.FC<JsonDisplayProps> = ({ data, dictionary }) => {
  const classes = useStyles();

  const Css = {
    overflowWrap: 'break-word',
    jsonTr: {
      height: '25px'
    },
    jsonTd: {
      padding: '5px',
      borderSpacing: '2px',
      borderRadius: '5px'
    },
    rowSpacer: {
      height: '2px'
    },
    rootElement: {
      padding: '5px',
      borderSpacing: '2px',
      backgroundColor: '#BBBBBB',
      fontWeight: 'bold',
      fontFamily: 'Arial',
      borderRadius: '5px',
    },
    subElement: {
      padding: '5px',
      borderSpacing: '2px',
      backgroundColor: '#DDDDDD',
      fontWeight: 'bold',
      fontFamily: 'Arial',
      borderRadius: '5px',
    },
    dataCell: {
      borderSpacing: '2px',
      backgroundColor: '#F1F1F1',
      fontFamily: 'Arial',
      borderRadius: '5px',
    }
  }


  return (
    <div className={classes.tableContainer}>
      <JsonTable json={data} css={Css} />
    </div>
  );
};

export default JsonDisplay;
